/* ===================== FOOTER ======================*/
.footer-top-area {
  padding: 50px 0;
  background: url(../../img/footer-bg.jpg) no-repeat scroll 0 0 / cover;
  position: relative;
  z-index: 1;
}

.footer-top-area:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: -1;
}

.single-footer {
  clear: both;
  color: #aaaeb1;
}

.quick-links {
  float: left;
  width: 45%;
}

.footer-logo {
  margin-bottom: 15px;
}

.single-footer h3,
.footer-address h3 {
  font-size: 17px;
  color: #eee;
  letter-spacing: 1px;
  margin-bottom: 30px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  position: relative;
}

.single-footer > h3:after,
.footer-address > h3:after {
  position: absolute;
  content: "";
  bottom: -13px;
  width: 18%;
  height: 2px;
  background: #3364a4;
  left: 0;
}

.quick_links {
  overflow: hidden;
}

.quick-links li a {
  color: #aaaeb1;
}

.quick-links li a:hover {
  color: #fff;
}

.footer-address {
  margin-top: 30px;
}

.newsletter_box {
  margin-top: 30px;
}

.newsletter_box form {
  width: 230px;
  height: 40px;
  background: #222;
  position: relative;
  border-radius: 5px;
}

.newsletter_box form input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #272727;
  height: 100%;
  border: medium none;
  padding: 5px 10px;
  color: #eee;
  border-radius: 5px;
}

.newsletter_box form button {
  position: absolute;
  border: medium none;
  background: transparent;
  right: 10px;
  height: 100%;
  color: #3364a4;
  cursor: pointer;
}

.footer-post-image {
  width: 70px;
  float: left;
  margin-right: 10px;
}

.footer-post-image img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.footer-post-text {
  overflow: hidden;
}

.single-footer-post {
  clear: both;
  margin-bottom: 15px;
  overflow: hidden;
}

.footer-post-text h3 {
  color: #eee;
  font-size: 14px;
  margin-bottom: 5px;
  text-transform: capitalize;
  line-height: 23px;
  font-weight: normal;
}

.footer-post-text h3 a {
  color: #aaaeb1;
}

.footer-post-text h3 a:hover {
  color: #eee;
}

.footer-post-text p {
  color: #647ea1;
  font-style: italic;
  font-size: 13px;
}

.footer-bottom-area {
  padding: 20px 0;
  background: #020202;
  border-top: 1px solid #333;
}

.copyright p {
  color: #aaa;
  font-size: 14px;
  text-transform: capitalize;
}

.copyright p svg {
  fill: #ec3323;
  margin: 0 2px;
}

.copyright p a {
  color: #3e85e3;
}

.footer-social ul {
  text-align: right;
}

.footer-social ul li {
  display: inline-block;
  margin-left: 10px;
}

.footer-social ul li a {
  display: block;
  color: #eee;
  font-size: 14px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .single-footer {
    margin: 20px auto 20px;
  }
  .newsletter_box form {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .single-footer {
    margin: 20px 0;
  }
  .copyright {
    text-align: center;
    margin-bottom: 10px;
  }
  .footer-social ul {
    text-align: center;
  }
  .newsletter_box form {
    width: 100%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .single-footer {
    margin: 20px 0;
  }
  .copyright {
    text-align: center;
    margin-bottom: 10px;
  }
  .footer-social ul {
    text-align: center;
  }
  .newsletter_box form {
    width: 100%;
  }
}
